import React, { useContext, useEffect, useState } from 'react';
import {
    GelSpinner,
    GelScreenDetectorContext,
    GelContainerLite,
    GelHeading2,
    GelParagraph
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import axios from 'axios';
import {API} from '../../constants/constants';
import { GET_NO_CLAIMDETAILS_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import { UserContext } from '../../common/usercontext/user.context';
import styled from '@emotion/styled';
import { useCookies } from 'react-cookie';

const NoClaims = () => {
    const { screen, isXsScreen, isLargerThanLgScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [contentDataLoaded, setcontentDataLoaded] = useState(false);
    const [contentfulData, setcontenfulData] = useState<null | { mmcNoClaimContentCollection: any }>(null);
    const [ssoCookies] = useCookies(['tpid']);
    const { contextData, setUserContext } = useContext(UserContext)
    const [headerText, setheaderText] = useState('');
    const [subText, setsubText] = useState('');

     if (headerText == '' && subText == '' && contentfulData) {
        if(contextData?.memberDetails?.memberId == 0){
            setheaderText(contentfulData?.mmcNoClaimContentCollection?.items[0].noClaimHeading);
            setsubText(contentfulData?.mmcNoClaimContentCollection?.items[0].noClaimDescription?.json?.content[0].content[0].value)
        }
        else if(contextData?.memberDetails?.memberId! > 0){
            setheaderText(contentfulData?.mmcNoClaimContentCollection?.items[0].noActiveClaimHeading);
            setsubText(contentfulData?.mmcNoClaimContentCollection?.items[0].noActiveClaimDescription?.json?.content[0].content[0].value)
        }
        else if (contextData?.memberDetails?.memberId == undefined){
            setheaderText(contentfulData?.mmcNoClaimContentCollection?.items[0].noClaimHeading);
            setsubText(contentfulData?.mmcNoClaimContentCollection?.items[0].noClaimDescription?.json?.content[0].content[0].value)
        }                   
    }

    useEffect(() => {
        axios.post(API.CONTENTFUL_URL, {
            query: GET_NO_CLAIMDETAILS_CONTENT_QUERY,
            variables: {
                fundName: ssoCookies.tpid
              }
        })
            .then(async (response) => {
                setcontentDataLoaded(true);
                setcontenfulData(response.data?.data);
            });
    }, []);
    
    const PageHeader = styled.div(() => ({
        padding: isXsScreen(screen) ? `${getGelTokens().global.sizeBaseX15}px ${getGelTokens().global.sizeBaseX5}px` : `${getGelTokens().global.sizeBaseX15}px ${getGelTokens().global.sizeBaseX10}px`,
        backgroundColor: getGelTokens().global.themeColorBackgroundLight
    }));

    if (!contentDataLoaded)    
    return (<GelSpinner medium overlay />);

    return (
        <GelContainerLite style={{ padding: getGelTokens().global.sizeNone }}>
        <PageHeader >
            <GelHeading2>{headerText}</GelHeading2>
            <GelParagraph style={{ paddingTop: getGelTokens().global.sizeBaseX3 }}> 
            <div dangerouslySetInnerHTML={{ __html: subText }} />
            </GelParagraph>
        </PageHeader>
        </GelContainerLite >
    );
};

export default NoClaims;