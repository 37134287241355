import React, { useContext, useEffect, useState } from 'react';
import {
    GelContainerLite,
    GelLabel,
    GelTooltip,
    GelRadio,
    GelButton,
    GelRadioButtonGroup} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import Card from '../../common/components/card';
import axios from 'axios';
import { API } from '../../constants/constants';
import { UPDATE_NOTIFICATION_PREFERENCES } from '../../graphql/queries/graphql-mutations';
import { useCookies } from 'react-cookie';
import SESSION_STORAGE_KEY from '../../constants/storage.constant';
import InlineAlert from '../common/inlineAlert';
import { UserContext } from '../../common/usercontext/user.context';

interface Props {
    contentfulData: any;
}

const NotificationPreferences: React.FC<Props> = ({contentfulData}) => {
    const [ssoCookies] = useCookies(['aid', 'rid', 'ssoid', 'tpid']);
    const { contextData, setUserContext } = useContext(UserContext)

    const [notificationPreference, setNotificationPreference] = useState(contextData?.memberDetails?.notificationPreference);

    // Load notification preference when contextData is available
    useEffect(() => {
        if (contextData?.memberDetails?.notificationPreference !== undefined) {
            setNotificationPreference(contextData.memberDetails.notificationPreference);
        }
    }, [contextData]);
    const [isPreferencesUpdatedSelected, setPreferencesUpdatedSelected] = useState(false);
    const [isPreferencesUpdated, setIsPreferencesUpdated] = useState(false);

    const onChange = event => {
        setNotificationPreference(parseInt(event.target.value));
        setPreferencesUpdatedSelected(true);
      };
    
    const onPreferencesUpdated = () => {
        axios.post(API.BASEURL, {
            query: UPDATE_NOTIFICATION_PREFERENCES,
            variables: {
                preference: notificationPreference
            }
        },
            {
                headers: {
                    'x-aid': ssoCookies.aid,
                    'x-rid': ssoCookies.rid,
                    'ssoid': ssoCookies.ssoid,
                    'tpid': ssoCookies.tpid,
                    'sid': window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN)
                }
            })
            .then(async (response) => {
                setIsPreferencesUpdated(true);
                setPreferencesUpdatedSelected(false);
                setUserContext({...contextData, memberDetails: {...contextData.memberDetails, notificationPreference: notificationPreference}});
            });
        
    };

    return (<Card title={contentfulData?.notificationPreferenceTitle}
        subTitle={contentfulData?.notificationPreferenceSubTitle}
        showFooter={false}>
        <GelContainerLite style={{ padding: `${getGelTokens().global.sizeBaseX8}px ${getGelTokens().global.sizeBaseX4}px` }}>
            {isPreferencesUpdated && <InlineAlert message={contentfulData?.notificationPreferenceUpdatedText} /> }
            
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px', paddingBottom: '8px' }}>
                <GelLabel>{contentfulData?.notificationPreferenceLabel}</GelLabel>
                <GelTooltip selfAdaptive>{contentfulData?.notificationPreferenceTooltip}</GelTooltip>
            </div>
            <GelRadioButtonGroup
                value={notificationPreference}
                onChange={onChange}
                vertical
                gap="small"
                required
                style={{paddingBottom: '20px'}}
            >
                <GelRadio value={1}>
                    {contentfulData?.notificationPreferenceSmsOption}
                </GelRadio>
                <GelRadio value={2}>
                    {contentfulData?.notificationPreferenceEmailOption}
                </GelRadio>
                <GelRadio value={3}>
                    {contentfulData?.notificationPreferenceNoneOption}
                </GelRadio>
            </GelRadioButtonGroup>

            <GelButton disabled={!isPreferencesUpdatedSelected} primary medium style={{width: '100%'}} onClick={onPreferencesUpdated}>
                {contentfulData?.notificationPreferenceButtonText}
            </GelButton>

        </GelContainerLite>
    </Card>)
}

export default NotificationPreferences;