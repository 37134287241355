import React from 'react';
import { GelParagraph} from '@tal-gel/components';

const InlineAlert = ({ message }) => {

    return (<div style={{ 
        display: 'flex', 
        border: '1px solid #80C342', 
        borderRadius: '4px', 
        marginBottom: '20px' }}>
        <div style={{
            width: '56px',
            height: '56px',
            background: '#E7F3D9',
            borderRight: '1px solid #80C342',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor" style={{ color: '#80C342' }}>
                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none"></circle>
                <path d="M9 12l2 2 4-4" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
            <GelParagraph>{message}</GelParagraph>
        </div>
    </div>);
}

export default InlineAlert;