import React, { useContext, useEffect, useState } from 'react';
import {
  GelForm, GelFormField,
  GelPasswordInput,
  useGelFormData,
  GelButton,
  GelBoxLayout,
  GelScreenDetectorContext,
  GelContainerLite,
  GelSelect,
  GelIcon,
  GelLabel,
  GelLink,
  GelEmailInput,
  GelRowLayout,
  GelHeading3,
  GelParagraph,
  GelSpinner
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { AuthnTransactionData, TRANSACTION_STATUS, getOktaUsername } from '../../common/authentication/auth.utils';
import { GET_SUPERFUND_DATA_QUERY } from '../../graphql/queries/graphql-queries.constant';
import { useNavigate, useParams } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';
import { AccountApi } from '../../common/api/auth.provider';
import { UserContext } from '../../common/usercontext/user.context';
import { GET_LOGINPAGE_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import { ThemeContext } from '../.././common/usercontext/themes.context';
import FundBanner from '../common/fundBanner';
import axios from 'axios';
import { API, THEME_NAME } from '../../constants/constants';
import { pushRouterHistory } from '../../common/helpers/helpers';
import { getThemeName } from '../../common/helpers/themeHelper';

const LoginPage = () => {

  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();
  const { screen, isXsScreen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
  const { setUserContext } = useContext(UserContext);
  const { setThemeContext } = useContext(ThemeContext);
  const [loginStatusMessage, setloginStatusMessage] = useState("");
  const [superfundId, setSuperFund] = useState(null);

  const [superFundLoaded, setsuperFundLoaded] = useState(false);
  const [contentDataLoaded, setcontentDataLoaded] = useState(false);
  const [contentfulData, setcontenfulData] = useState<null | { mmcLoginPageCollection: any }>(null);
  const [superFunds, setsuperFunds] = useState<null | { superFundList: any }>(null);
  const [isSuperFundFromUrl, setIsSuperFundFromUrl] = useState(false);

  let { fund } = useParams();

  const {
    formData,
    onFormDataChange,
    onFormDataReset,
    resetForm
  } = useGelFormData({
    email: '',
    password: '',
    superfundId: ''
  });

  const onSuperFundChange = event => {
    setSuperFund(event.target.value);
  };


  useEffect(() => {
    //pushRouterHistory()

    setThemeContext({
      theme: getFundTheme(),
      fund: fund ? fund : THEME_NAME.TAL
    });


    if (authState && authState.isAuthenticated) {
      navigate("/claims");
    }

    if (fund)
      loadDataFundSpecific();
    else
      loadDataGeneric();
  }, []);

  const getFundTheme = () => {
    switch (fund) {
      case THEME_NAME.FOODSUPER:
        return THEME_NAME.FOODSUPER;
      default:
        return THEME_NAME.TAL;
    }
  };

  const loadDataFundSpecific = () => {
    axios.post(API.BASEURL, {
      query: GET_SUPERFUND_DATA_QUERY,
    })
      .then(async (response) => {
        setsuperFundLoaded(true);
        setsuperFunds(response.data?.data);
        validateUrlParamFund(response.data?.data);
      });
  }

  const loadDataGeneric = () => {
    axios.post(API.CONTENTFUL_URL, {
      query: GET_LOGINPAGE_CONTENT_QUERY,
    })
      .then(async (response) => {
        setcontentDataLoaded(true);
        setcontenfulData(response.data?.data);
      });

    axios.post(API.BASEURL, {
      query: GET_SUPERFUND_DATA_QUERY,
    })
      .then(async (response) => {
        setsuperFundLoaded(true);
        setsuperFunds(response.data?.data);
      });
  }

  const validateUrlParamFund = (superFunds) => {

    if (fund) {
      var superFund = superFunds?.superFundList?.filter(superFund => superFund.displayName == fund);

      if (superFund?.length > 0) {
        var selSuperFundId = superFund[0]?.superfundId;

        setIsSuperFundFromUrl(true);
        setSuperFund(selSuperFundId);

        axios.post(API.CONTENTFUL_URL, {
          query: GET_LOGINPAGE_CONTENT_QUERY,
          variables: {
            fundName: superFunds?.superFundList?.filter(superFund => superFund.superfundId == selSuperFundId)[0].superFundPrefix.toLowerCase()
          }
        })
          .then(async (response) => {
            setcontentDataLoaded(true);
            setcontenfulData(response.data?.data);
          });

      }
      else
        navigate("/invalidfund");
    }
  }

  const onSubmit = () => {
    var umbrellaFunds = superFunds?.superFundList?.find(x => x.superfundId == superfundId)?.umbrellaFundIds?.split(',') ?? [];
    umbrellaFunds.push(superfundId);
    oktaLogin(umbrellaFunds);
  };

  const oktaLogin = (superFundIds: string[]) => {

    var superfundId = superFundIds.pop();

    const oktaUsername = getOktaUsername(superfundId!, formData.email);
    oktaAuth.revokeAccessToken()
      .then(() => {
        oktaAuth.signIn({
          username: oktaUsername,
          password: formData.password
        })
          .then((res: AuthnTransactionData) => {
            if (res.status === TRANSACTION_STATUS.MFA_REQUIRED) {
              setloginStatusMessage("Valid credentials, MFA Required");
              const factor = res.factors?.find(f => f.factorType === 'sms');
              if (!factor) {
                console.log(`User not enrolled in sms factor`);
              }
              const phoneNumber = factor?.profile.phoneNumber.replaceAll(' ', '').replaceAll('X', '*');
              navigate("/verifycode");
              setUserContext({ memberDetails: { stateToken: res.data?.stateToken!, mobileNumber: phoneNumber, factorId: factor?.id, policyManagerId: Number(superfundId) }, claims: [] });
              AccountApi.sendMfaCode(factor?.id, res.data?.stateToken!)
                .then(response => response.json())
                .then(data => {
                  console.log("sms sent", data)
                })
                .catch(httpError => {
                  console.log('failed to send MFA sms', httpError);
                  setloginStatusMessage("Failed sending sms. Please try again");
                });

            } else if (res.status === TRANSACTION_STATUS.SUCCESS) {
              console.log('MFA not required for member, authentication succcessful');
              // Store automation test account password in session storage for redirect to EFT/TFN microsites
              //Storage.setItem(SESSION_STORAGE_KEY.ACCOUNT_TYPE, ACCOUNT_TYPE.AUTOMATION_ACCOUNT);
              // Retreive ID & Access token from Okta

              oktaAuth.signInWithRedirect({ sessionToken: res.data?.sessionToken });
            } else if (res.status === TRANSACTION_STATUS.LOCKED_OUT) {
              setloginStatusMessage("Your account has been locked. Please reach out to your Claims Consultant");
            }
          })
          .catch(httpError => {
            if (superFundIds?.length > 0) {
              console.log('check for umbrellafunds');
              oktaLogin(superFundIds);
            } else {
              console.log('failed to login', httpError);
              setloginStatusMessage(contentfulData?.mmcLoginPageCollection.items[0]?.errorMessageContent.invalidLoginMsg);
            }
          });
      })
  }

  const navigateToSignUp = () => {
    navigate("/signup");
  };

  const navigateToPasswordReset = () => {
    navigate("/forgotPassword");
  };

  if (!contentDataLoaded || !superFundLoaded)
    return (<GelSpinner medium overlay />)

  return (
    <GelContainerLite style={{
      paddingRight: getGelTokens().global.sizeNone,
      paddingLeft: getGelTokens().global.sizeNone,
      paddingBottom: getGelTokens().global.sizeBaseUnit * 15,
      paddingTop: getGelTokens().global.sizeBaseUnit * 15,
      overflow: "hidden"
    }}>
      <GelBoxLayout alignment="start" space={[1, 4]} gutter="none">
        <GelForm
          labelAtTop={true}
          width={isXsScreen(screen) ?
            '100%' : getGelTokens().global.sizeBaseUnit * 110
          }
          {...!isXsScreen(screen) && {
            labelWidth: getGelTokens().global.sizeBaseUnit * 50
          }}
          fieldGutter={getGelTokens().global.sizeBaseUnit * 10}
          disableOnSubmit
          onSubmit={onSubmit}
          reset={resetForm}
          onReset={onFormDataReset}
          parseResponseBody={false}
          style={{
            paddingRight: getGelTokens().global.sizeBaseUnit * 16,
            paddingLeft: getGelTokens().global.sizeBaseUnit * 16
          }}
        >
          <GelRowLayout gutter="medium" style={{ paddingLeft: getGelTokens().global.sizeBaseUnit * 3 }}>
            <GelHeading3>
              {contentfulData?.mmcLoginPageCollection?.items[0]?.headerText}
            </GelHeading3>
            <GelParagraph style={{ paddingTop: getGelTokens().global.sizeBaseUnit * 2 }}>{contentfulData?.mmcLoginPageCollection?.items[0]?.subText}</GelParagraph>

            {!isSuperFundFromUrl &&
              <GelFormField
                label={contentfulData?.mmcLoginPageCollection.items[0]?.superFundText} >
                <GelSelect
                  name="superfund"
                  onChange={onSuperFundChange}
                  placeholder="Please select"
                  options={superFunds?.superFundList?.filter(superFund => superFund.hideInDropDown == false)?.map((superFund) => (
                    { label: superFund.superfundName, value: superFund.superfundId }
                  ))}
                  required
                  requiredErrorMsg={contentfulData?.mmcLoginPageCollection.items[0]?.errorMessageContent.superFundReqMsg}
                />
              </GelFormField>}
            <GelFormField
              label={contentfulData?.mmcLoginPageCollection.items[0]?.emailText} >
              <GelEmailInput
                name="email"
                onChange={onFormDataChange}
                required
                value={formData.email}
                requiredErrorMsg={contentfulData?.mmcLoginPageCollection.items[0]?.errorMessageContent.emailReqMsg}
                formatErrorMsg={contentfulData?.mmcLoginPageCollection.items[0]?.errorMessageContent.emailFormatMsg}
              />

            </GelFormField>
            <GelFormField
              label={contentfulData?.mmcLoginPageCollection.items[0]?.passwordText}
            >
              <GelPasswordInput
                name="password"
                value={formData.password}
                onChange={onFormDataChange}
                required
                requiredErrorMsg={contentfulData?.mmcLoginPageCollection.items[0]?.errorMessageContent.passwordReqMsg}
              />
            </GelFormField>
            {loginStatusMessage &&
              <GelBoxLayout space={[1, 8]}>
                <GelIcon color={getGelTokens().global.themeColorTextDanger} name="AlertCircle" inline />
                <GelLabel style={{ color: getGelTokens().global.themeColorTextDanger }}>
                  {loginStatusMessage}
                </GelLabel>
              </GelBoxLayout>
            }
            <GelFormField style={{ paddingTop: getGelTokens().global.sizeBaseUnit * 4 }}>
              <GelButton
                name="" style={{
                  width: "100%"
                }}
                primary large submit
              >
                {contentfulData?.mmcLoginPageCollection.items[0]?.loginButtonText}
              </GelButton>
            </GelFormField>

            <div style={{ paddingTop: getGelTokens().global.sizeBaseUnit * 4 }}>
              <GelLabel style={{
                fontWeight: 100
              }}>{contentfulData?.mmcLoginPageCollection?.items[0]?.forgotPasswordText}
                <GelLink onClick={navigateToPasswordReset}> {contentfulData?.mmcLoginPageCollection?.items[0]?.forgotPasswordLink}</GelLink>
              </GelLabel>
            </div>

            <div style={{ paddingBottom: getGelTokens().global.sizeBaseUnit * 4 }}>
              <GelLabel style={{
                fontWeight: 100
              }}>{contentfulData?.mmcLoginPageCollection?.items[0]?.signUpText}
                <GelLink onClick={navigateToSignUp}> {contentfulData?.mmcLoginPageCollection?.items[0]?.signUpLink}</GelLink>
              </GelLabel>
            </div>

          </GelRowLayout>
        </GelForm>
        {isLargerThanSmScreen(screen) &&
          <FundBanner></FundBanner>}
      </GelBoxLayout>
    </GelContainerLite>
  );
};

export default LoginPage;
