import { useOktaAuth } from "@okta/okta-react";
import config from "../../config";
import { AuthnTransaction } from "@okta/okta-auth-js";

export const TRANSACTION_STATUS = {
  SUCCESS: 'SUCCESS',
  MFA_REQUIRED: 'MFA_REQUIRED',
  PASSWORD_RESET: 'PASSWORD_RESET',
  MFA_ENROLL: 'MFA_ENROLL',
  UNAUTHENTICATED: 'UNAUTHENTICATED', //Status when user attempts to login without password
  LOCKED_OUT: 'LOCKED_OUT',
};

export const getOktaUsername = (superfundId: string, userEmail: string) => {
  return `${superfundId}.${userEmail}`;
};


export const isMicrositeRedirect = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return (searchParams.has('source'));
};

export interface AuthnTransactionData extends AuthnTransaction
{
  data?: AuthSessionTokens;
}

export interface AuthSessionTokens
{
  stateToken: string;
  status: string;
  sessionToken:string;
}

export const FLOW_TYPE = {
  REGISTER: 'RegisterUser',
  FORGOT_PASSWORD: 'ForgotPassword',
  MULTI_FACTOR_AUTHENTICATION: 'MultiFactorAuthentication'
};


