import React, { useContext, useEffect, useState } from 'react';
import {
    GelForm, GelFormField, GelTextInput,
    useGelFormData,
    GelButton,
    GelBoxLayout,
    GelContainerLite,
    GelScreenDetectorContext,
    GelLabel,
    GelLink,
    GelIcon,
    GelRowLayout,
    GelHeading3,
    GelParagraph,
    GelSpinner
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { AccountApi } from '../../common/api/auth.provider';
import { UserContext } from '../../common/usercontext/user.context';
import { useOktaAuth } from '@okta/okta-react';
import FundBanner from '../common/fundBanner';
import { GET_TWOFACTORPAGE_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import { API, OKTA_ERROR_ACCOUNT_LOCKED, OktaRecentSmsErrorCode } from '../../constants/constants';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { ACTIVATION_EMAIL } from '../../graphql/queries/graphql-mutations';
import { useNavigate } from 'react-router-dom';
import SESSION_STORAGE_KEY from '../../constants/storage.constant';

const TwoFactorAuth = () => {
    const { oktaAuth } = useOktaAuth();
    const { isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [mfaStatusMessage, setMfaStatusMessage] = useState("");
    const [sessionTokenId, setsessionToken] = useState("");
    const [contentDataLoaded, setcontentDataLoaded] = useState(false);
    const [isAccountLocked, setAccountLocked] = useState(false);
    const [contentfulData, setcontenfulData] = useState<null | { mmcTwoFactorPageCollection: any }>(null);
    const [ssoCookies] = useCookies(['aid', 'rid', 'ssoid', 'tpid']);
    const navigate = useNavigate();

    const {
        formData,
        onFormDataChange,
        onFormDataReset,
        resetForm
    } = useGelFormData({
        verificationCode: '',
        memberNumber: ''
    });

    const { contextData } = useContext(UserContext)

    const onSubmit = () => {
        AccountApi.verifyMfaCode(contextData.memberDetails?.factorId!, contextData.memberDetails?.stateToken!, formData.verificationCode)
            .then(response => response.json())
            .then(data => {
                console.log("okta response ->", data)
                if (data?.status == "SUCCESS") { 
                    if (contextData.memberDetails?.oktaEmail) {
                        window.sessionStorage.setItem(SESSION_STORAGE_KEY.SEND_ACTIVATION_EMAIL, contextData.memberDetails?.oktaEmail);
                    }
                    oktaAuth.signInWithRedirect({ sessionToken: data.sessionToken });                    
                }
                else {
                    if(data?.errorLink == OKTA_ERROR_ACCOUNT_LOCKED){
                        setAccountLocked(true);
                        setMfaStatusMessage('Invalid 6-digit code. Your account has been locked. Please reach out to your Claims Consultant');
                    }
                    else {
                        setMfaStatusMessage(contentfulData?.mmcTwoFactorPageCollection.items[0]?.errorMessageContent.verificationFailedMsg);
                    }
                }
            }).catch(httpError => {
                console.log('MFA verification failed', httpError);
            });
    };

    const onResend = () => {
        AccountApi.sendMfaCode(contextData.memberDetails?.factorId!, contextData.memberDetails?.stateToken!)
            .then(response => response.json())
            .then(data => {
                if (data?.errorCode){
                    if(data.errorCode == OktaRecentSmsErrorCode)
                        setMfaStatusMessage(contentfulData?.mmcTwoFactorPageCollection.items[0]?.errorMessageContent.smsSentRecentlyMsg);
                    else
                        setMfaStatusMessage(contentfulData?.mmcTwoFactorPageCollection.items[0]?.errorMessageContent.sendSmsFailed);
                }
                else
                    setMfaStatusMessage('');

            })
            .catch(httpError => {
                console.log('failed to send MFA sms', httpError);
                setMfaStatusMessage(contentfulData?.mmcTwoFactorPageCollection.items[0]?.errorMessageContent.sendSmsFailed);
            });
    }

    const navigateToMobileUpdate = () => {
        navigate("/confirmmember");
    }

    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;

    useEffect(() => {
        axios.post(API.CONTENTFUL_URL, {
            query: GET_TWOFACTORPAGE_CONTENT_QUERY,
        })
            .then(async (response) => {
                setcontentDataLoaded(true);
                setcontenfulData(response.data?.data);
            });

    }, []);

    if (!contextData.memberDetails?.stateToken || !contentDataLoaded)
        return (<GelSpinner medium overlay />)

    return (
        <GelContainerLite style={{
            paddingRight: getGelTokens().global.sizeNone,
            paddingLeft: getGelTokens().global.sizeNone,
            paddingBottom: getGelTokens().global.sizeBaseUnit * 15,
            paddingTop: getGelTokens().global.sizeBaseUnit * 15, overflow: "hidden"
        }}>
            <GelBoxLayout alignment="start" space={[1, 4]} gutter="none">
                <GelForm
                    labelAtTop={true}
                    width={isXsScreen(screen) ?
                        '100%' : getGelTokens().global.sizeBaseUnit * 110
                    }
                    {...!isXsScreen(screen) && {
                        labelWidth: getGelTokens().global.sizeBaseUnit * 50
                    }}
                    fieldGutter={getGelTokens().global.sizeBaseUnit * 10}
                    disableOnSubmit
                    onSubmit={onSubmit}
                    reset={resetForm}
                    onReset={onFormDataReset}
                    parseResponseBody={false}
                    style={{ 
                        paddingRight: getGelTokens().global.sizeBaseUnit * 16, 
                        paddingLeft: getGelTokens().global.sizeBaseUnit * 16 }}
                >
                    <GelRowLayout gutter="medium" style={{ paddingLeft: getGelTokens().global.sizeBaseUnit * 3 }}>
                        <GelHeading3>
                            {contentfulData?.mmcTwoFactorPageCollection.items[0]?.headerText} {contextData.memberDetails?.mobileNumber}
                        </GelHeading3>
                        <GelParagraph style={{ paddingTop: getGelTokens().global.sizeBaseUnit * 2 }}>{contentfulData?.mmcTwoFactorPageCollection.items[0]?.subText}</GelParagraph>
                        <div style={{ paddingBottom: getGelTokens().global.sizeBaseUnit * 2 }}>
                            <GelLabel style={{
                                fontWeight: 100
                            }}>
                                {contentfulData?.mmcTwoFactorPageCollection?.items[0]?.headerSubText}
                            </GelLabel>
                        </div>
                        <GelFormField
                            label={contentfulData?.mmcTwoFactorPageCollection.items[0]?.verificationCodeText}
                        >
                            <GelTextInput
                                name="verificationCode"
                                onChange={onFormDataChange}
                                value={formData.verificationCode}
                                required
                                requiredErrorMsg={contentfulData?.mmcTwoFactorPageCollection.items[0]?.errorMessageContent.verifyCodeReqMsg}
                            />

                        </GelFormField>

                        {mfaStatusMessage &&
                            <GelLabel style={{
                                color: getGelTokens().global.themeColorTextDanger
                            }}>
                                <GelIcon color={getGelTokens().global.themeColorTextDanger} name="AlertCircle" inline />{mfaStatusMessage}
                            </GelLabel>}

                        <GelFormField style={{ paddingTop: getGelTokens().global.sizeBaseUnit * 4 }}>
                            <GelButton
                                name="" style={{
                                    width: "100%",
                                }}
                                primary large submit
                                disabled={isAccountLocked}
                            >
                                {contentfulData?.mmcTwoFactorPageCollection.items[0]?.verifyButtonText}
                            </GelButton>
                        </GelFormField>

                        <div style={{ paddingTop: getGelTokens().global.sizeBaseUnit * 4 }}>
                            <GelLabel style={{
                                fontWeight: 100,
                                paddingRight: getGelTokens().global.sizeBaseX2
                            }}>
                                {contentfulData?.mmcTwoFactorPageCollection.items[0]?.didNotReceiveCodeText}
                                <GelLink onClick={onResend}>  {contentfulData?.mmcTwoFactorPageCollection.items[0]?.resendCodeText}</GelLink>
                            </GelLabel>
                        </div>

                        <div style={{ paddingBottom: getGelTokens().global.sizeBaseUnit * 4 }}>
                            <GelLabel style={{
                                fontWeight: 100,
                                paddingRight: getGelTokens().global.sizeBaseX2
                            }}>
                                {contentfulData?.mmcTwoFactorPageCollection.items[0]?.notYourMobileText}
                                <GelLink onClick={navigateToMobileUpdate}>  {contentfulData?.mmcTwoFactorPageCollection.items[0]?.resetMobileLink}</GelLink>
                            </GelLabel>
                        </div>
                    </GelRowLayout>
                </GelForm>
                {isLargerThanSmScreen(screen) &&
                    <FundBanner>
                    </FundBanner>}
            </GelBoxLayout>
        </GelContainerLite>

    )
}

export default TwoFactorAuth;