import { THEME_CODE, THEME_NAME } from "../../constants/constants";

const themeCodeMap = {
    [THEME_NAME.TAL]: THEME_CODE.TAL,
    [THEME_NAME.FOODSUPER]: THEME_CODE.FOODSUPER,
    [THEME_NAME.AWARE]: THEME_CODE.AWARE,
    [THEME_NAME.TWUSUPER]: THEME_CODE.TWUSUPER,
    [THEME_NAME.ANZSUPER]: THEME_CODE.ANZSUPER,
    [THEME_NAME.MLCSUPER]: THEME_CODE.MLCSUPER
};

export const getThemeCode = (theme: string) => {
    return themeCodeMap[theme] || THEME_CODE.TAL;
};