import React, { useContext, useEffect, useState } from "react";
import {
    GelBoxLayout,
    GelButton,
    GelContainerLite,
    GelLink,
    GelScreenDetectorContext,
    GelPageLayoutContext
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { UserContext } from "../../common/usercontext/user.context";
import AuthenticationService from "../../common/authentication/auth.service";
import { ThemeContext } from "../../common/usercontext/themes.context";
import { API, THEME_CODE, THEME_NAME } from "../../constants/constants";
import axios from "axios";
import { useCookies } from "react-cookie";
import { GET_FUND_BANNER_DETAILS_CONTENT_QUERY } from "../../graphql/queries/graphql-contentful-queries";
import { getThemeCode } from '../../common/helpers/themeHelper';


const Header = () => {
    const { contextData } = useContext(UserContext)
    const isNeedHelpVisible = contextData.claims?.length > 0 && !contextData.memberDetails?.displayLegalDisclosure;
    const { isStandAloneAuthenticated, clearSession } = AuthenticationService();
    const { themeData, setThemeContext } = useContext(ThemeContext);
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const { openSideNav } = useContext(GelPageLayoutContext);
    const [ssoCookies] = useCookies(['tpid']);
    const [contentfulData, setcontenfulData] = useState<null | { mmcFundBannerCollection: any }>(null);
    const signOut = async () => {
        clearSession();
    };
    useEffect(() => {

        if (!themeData.fund) {
            themeData.fund = contextData.memberDetails?.policyManagerId === 122 ? THEME_NAME.FOODSUPER : THEME_NAME.TAL;
        }

        axios.post(API.CONTENTFUL_URL, {
            query: GET_FUND_BANNER_DETAILS_CONTENT_QUERY,
            variables: {
                fundName: ssoCookies.tpid ? ssoCookies.tpid : getThemeCode(themeData.fund)
            }
        })
            .then(async (response) => {
                setcontenfulData(response.data?.data);
            });
    }, []);

    const closeNavbar = () => {
        if (isXsScreen(screen)) {
            openSideNav(false);
        }
    }

    return (
        <GelContainerLite gutter="medium"
            style={{ boxShadow: `0 4px 2px -2px ${getGelTokens().global.themeColorBorderDefault}` }}
        >
            <GelBoxLayout space={isXsScreen(screen) ? [20, 1, 1] : [20, 0.5, 0.5]} gutter={1} >
                <div style={{ float: "left", display: "flex" }}>
                    <img id="brand-logo" alt="logo" src={contentfulData?.mmcFundBannerCollection?.items[0].brandLogo.url}
                        style={{ display: "block", margin: "auto" }} />
                </div>

                <div style={{ float: "right", paddingRight: getGelTokens().global.sizeBaseUnit * 3 }} onClick={closeNavbar}>
                    {isNeedHelpVisible && <GelLink href="#/help">Need Help?</GelLink>}
                </div>

                <div style={{ float: "right", height: getGelTokens().global.sizeBaseUnit * 12, paddingTop: getGelTokens().global.sizeBaseUnit }}>
                    {isStandAloneAuthenticated &&
                        <GelButton secondary medium onClick={signOut} style={{ width: getGelTokens().global.sizeBaseUnit * 25 }}>
                            Log Out
                        </GelButton>}
                </div>
            </GelBoxLayout>
        </GelContainerLite>
    );
};

export default Header;